import React, { useState, useEffect } from 'react';
import Map, { Source, Layer } from 'react-map-gl';

import mapStyle from '../assets/mapStyle.json';
import LOCATION_COORDINATES from '../constants/locations';

const US_MID_DATA_URL = '/fetch-mid-temp';
const US_EAST_DATA_URL = '/fetch-east-temp';
const US_WEST_DATA_URL = '/fetch-west-temp';
const US_DATA_URL = '/fetch-us-temp';

const initialLocationName = 'us_mid';
const initialCoordinates = LOCATION_COORDINATES[initialLocationName] || LOCATION_COORDINATES['us_mid'];

const Mapbox = () => {
  const [viewport, setViewport] = useState({
    latitude: initialCoordinates.latitude,
    longitude: initialCoordinates.longitude,
    zoom: 5
  });
  const [usMidData, setUsMidData] = useState(null); // New state for US Mid data
  const [usEastData, setUsEastData] = useState(null); // New state for US Mid data
  const [usWestData, setUsWestData] = useState(null); // New state for US Mid data
  const [usData, setUsData] = useState(null); // New state for US Mid data

  const fetchTempData = async (url, setData) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(`Failed to fetch data from ${url}:`, error);
    }
  };

  useEffect(() => {
    // Fetch US Mid data first
    fetchTempData(US_MID_DATA_URL, setUsMidData).then(() => {
      // Once US Mid data is fetched and set, fetch East data
      fetchTempData(US_EAST_DATA_URL, setUsEastData).then(() => {
        // After East data is fetched and set, fetch West data
        fetchTempData(US_WEST_DATA_URL, setUsWestData).then(() => {
          fetchTempData(US_DATA_URL, setUsData);
        });
      });
    });
  }, []);

  const handleViewportChange = (newViewport) => {
    setViewport({ ...viewport, ...newViewport });
  };

  const layerStyle = {
    id: 'tmax-temp',
    type: 'circle',
    paint: {
      'circle-color': [
        'interpolate',
        ['linear'],
        ['get', 'maxTemp'],
        30, '#3288bd',
        70, '#fee08b',
        100, '#d53e4f',
      ],
      'circle-opacity': 0.8,
      'circle-radius': 10
    }
  };

  const textLayerStyle = {
    id: 'tmax-temp-text',
    type: 'symbol',
    layout: {
      'text-field': ['get', 'maxTemp'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12
    },
    paint: {
      'text-color': 'rgba(0,0,0,0.8)',
    }
  };

  const isSmallDevice = window.innerWidth < 768;
  const mapContainerStyle = isSmallDevice ? { width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0 } : { width: 948, height: 500, margin: 'auto' };

  return (
    <div style={mapContainerStyle} className='map-container'>
      <Map
        {...viewport}
        mapStyle={mapStyle}
        onMove={evt => handleViewportChange(evt.viewState)}
        mapboxAccessToken='pk.eyJ1Ijoic3RlcGhlbm0yMjUiLCJhIjoiY2xvbHB5NTI0MjhodjJrbzFuamd2M2s1OCJ9.sctfQBx-ehAw4oreP4So2g'
      >
        {usMidData && (
          <Source
            id="tmax-source"
            type="geojson"
            data={usMidData}
          >
            <Layer {...layerStyle} />
            <Layer {...textLayerStyle} />
          </Source>
        )}
        {usEastData && (
          <Source
            id="tmax-source"
            type="geojson"
            data={usEastData}
          >
            <Layer {...layerStyle} />
            <Layer {...textLayerStyle} />
          </Source>
        )}
        {usWestData && (
          <Source
            id="tmax-source"
            type="geojson"
            data={usWestData}
          >
            <Layer {...layerStyle} />
            <Layer {...textLayerStyle} />
          </Source>
        )}
        {usData && (
          <Source
            id="tmax-source"
            type="geojson"
            data={usData}
          >
            <Layer {...layerStyle} />
            <Layer {...textLayerStyle} />
          </Source>
        )}
      </Map>
    </div>
  );
};

export default Mapbox;
